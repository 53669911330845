import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.matchMedia({
  "(min-width: 768px)": function minWidth768px() {
    // 画像のパララックス
    document.querySelectorAll(".js-parallax").forEach(function (wrap) {
      gsap.to(wrap, {
        y: "-25%",
        // 画像をどれだけの幅動かすか
        scrollTrigger: {
          trigger: wrap,
          start: "top bottom",
          // どこからアニメーションするか（表示領域の上端 画面下端）
          end: "300 top",
          // どこまでアニメーションするか（表示領域の高さ 画面上端）
          scrub: 0
          // markers: true,
        }
      });
    });
    // ふわっと出現
    document.querySelectorAll(".js-section").forEach(function (el) {
      ScrollTrigger.create({
        trigger: el,
        start: "top 80%",
        toggleClass: {
          targets: el,
          className: "is-on"
        }
        // markers: true,
      });
    });
  },

  "(max-width: 767px)": function maxWidth767px() {
    // 画像のパララックス
    document.querySelectorAll(".js-parallax").forEach(function (wrap) {
      gsap.to(wrap, {
        y: "-25%",
        scrollTrigger: {
          trigger: wrap,
          start: "top bottom",
          end: "124 top",
          scrub: 0
          // markers: true,
        }
      });
    });
    // ふわっと出現
    document.querySelectorAll(".js-section").forEach(function (el) {
      ScrollTrigger.create({
        trigger: el,
        start: "top 90%",
        toggleClass: {
          targets: el,
          className: "is-on"
        }
        // markers: true,
      });
    });
  }
});