import "slick-carousel";
$(".js-slider").slick({
  dots: true,
  infinite: true,
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  focusOnSelect: true,
  appendArrows: $(".p-home__about-slide-arrows"),
  appendDots: $(".p-home__about-slide-dots"),
  dotsClass: "p-home__about-slide-dots-list",
  prevArrow: '<span class="p-home__about-slide-arrow is-prev">',
  nextArrow: '<span class="p-home__about-slide-arrow is-next">',
  autoplaySpeed: 3000,
  autoplay: true,
  responsive: [{
    breakpoint: 767,
    //ブレイクポイントを指定
    settings: {
      variableWidth: false,
      centerMode: false
    }
  }]
});