// contactページだけで動作させる
if (document.querySelector(".p-contact") !== null) {
  // 全角数字並びに全角ハイフンを半角に変換する関数
  var convertToHalfWidth = function convertToHalfWidth(str) {
    // 全角数字を半角数字に変換
    var result = str.replace(/[０-９]/g, function (s) {
      // Unicodeの位置をずらす
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
    // 全角ハイフンを半角ハイフンに変換
    result = result.replace(/－/g, "-");
    return result;
  };
  // セレクトボックスで「ご選択ください」の初期値が選択されているときだけ色を変える
  var select = document.querySelector(".js-select");
  select.addEventListener("change", function () {
    var selectedOption = this.options[this.selectedIndex];
    if (!selectedOption.value) {
      this.style.color = "";
    } else {
      this.style.color = "#443635";
    }
  });

  // // ◆ファイル選択フォームの装飾をするためにデフォルトのinput要素は見えなくなるので、ファイル名表示などの処理を再現
  // // ファイル選択ダイアログが表示された後に、ファイル名を表示する関数
  // function showFileName(input) {
  //   const file = input.files[0];
  //   const fileName = input.parentElement.querySelector(
  //     ".p-contact__form-content-file-name"
  //   );
  //   fileName.textContent = file.name;
  // }
  // // ファイル選択ダイアログを表示するためのクリックイベントを登録する
  // const fileInputs = document.querySelectorAll('input[type="file"]');
  // for (let i = 0; i < fileInputs.length; i++) {
  //   const fileInput = fileInputs[i];
  //   const fileLabel = fileInput.previousElementSibling;
  //   const fileButton = fileLabel.querySelector(
  //     ".p-contact__form-content-file-button"
  //   );
  //   fileButton.addEventListener("click", function (e) {
  //     e.preventDefault();
  //     fileInput.click();
  //   });

  //   fileInput.addEventListener("change", function () {
  //     showFileName(fileInput);
  //   });
  // }
  // // 対象のフォームグループ要素を取得する
  // const formGroups = document.querySelectorAll(
  //   ".p-contact__form-content-file-group-item"
  // );
  // for (let i = 0; i < formGroups.length; i++) {
  //   const formGroup = formGroups[i];

  //   // 子要素にmw-wp-form_fileクラスがある場合は、ファイル名表示欄を非表示にする
  //   const fileName = formGroup.querySelector(
  //     ".p-contact__form-content-file-name"
  //   );
  //   const hasMWClass = formGroup.querySelector(".mw-wp-form_file") !== null;
  //   if (hasMWClass && fileName) {
  //     fileName.style.display = "none";
  //   }
  // }

  // ◆項目のエラーがあったらページ上部にエラー文を表示させる
  window.onload = function () {
    if (document.getElementsByClassName("p-contact__form-error").length > 0) {
      var errorMessage = document.createElement("p");
      errorMessage.className = "p-contact__form-notice";
      errorMessage.innerHTML = "入力内容に誤りがあります<span class='p-contact__form-notice-sub'>該当箇所を確認の上修正してください</span>";
      document.getElementsByClassName("js-notice-container")[0].appendChild(errorMessage);
    }
  };

  // ◆指定したinput要素からフォーカスが外れた際に入力値を半角に変換する
  var inputSelector = ".js-form-number";
  document.querySelectorAll(inputSelector).forEach(function (inputElement) {
    // フォーカスが外れたときのイベントを登録する
    inputElement.addEventListener("blur", function (event) {
      // input要素から入力値を取得する
      var inputValue = event.target.value;
      // 全角数字並びに全角ハイフンを半角に変換する
      var convertedValue = convertToHalfWidth(inputValue);
      // 変換後の値をinput要素に反映する
      event.target.value = convertedValue;
    });
  });
}