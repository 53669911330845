// 画面の高さに対するスクロール位置を計算して、is-scrolledクラスを付与/削除する関数
function addIsOnClass() {
  // 画面幅が769px以下かどうかを判定
  var isSmallScreen = window.innerWidth <= 767;

  // 画面幅に応じてスクロール位置を計算
  var scrollPosition = isSmallScreen ? window.innerHeight * 0.8 : window.innerHeight * 0.8;

  // スクロール位置が指定値以上になったらis-scrolledクラスを付与、それ以外の場合は削除
  if (window.scrollY >= scrollPosition) {
    document.querySelector("body").classList.add("is-scrolled");
  } else {
    document.querySelector("body").classList.remove("is-scrolled");
  }
}

// スクロール位置が変化したら、addIsOnClass関数を呼び出すようにイベントリスナーを設定
window.addEventListener("scroll", addIsOnClass);

// 画面幅が変化したら、addIsOnClass関数を呼び出すようにイベントリスナーを設定
window.addEventListener("resize", addIsOnClass);